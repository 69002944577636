<template>
  <LiefengContent>
    <template v-slot:title>{{'广州市“社区随约服务网上驿站”平台运营数据统计'}}</template>
    <template v-slot:contentArea>
      <div
        style="
          display: flex;
          align-items: stretch;
          height: 150px;
          justify-content: space-around;
          margin: 20px 0;
        "
      >
        <Card class="cardsty" style="background: #2d8cf0">
          <div>
            <h1>{{viewNum || 0}}</h1>
            <p>平台访问人次（累计）</p>
          </div>
        </Card>
        <Card class="cardsty" style="background: ##47cb89">
          <div>
            <h1>{{enjoyNum || 0}}</h1>
            <p>享受服务人数（累计）</p>
          </div>
        </Card>
      </div>
      <Card style="width:98%;margin:50px 1% 0 1%;">
        <Row type="flex" justify="space-between">
          <Col span="24" style="text-align:center;font-size:16px;font-weight:bold;">
            {{'最受欢迎的日常服务点击量排行（疫情防控服务除外）'}}
            <VeHistogram :data="welcomeData" :extend="hisExtend"></VeHistogram>
          </Col>
        </Row>
      </Card>
      <div style="display:flex; justify-content:center;margin:50px 1% 0 1%;">
        <Card
          style="width:30%;margin-right:10px;text-align:center;font-size:16px;font-weight:bold;"
        >
          {{'最受欢迎服务参与数分析'}}
          <VePie
            style="margin-top:-20px;"
            :data="bestWelecome"
            :settings="settingsparam"
            :legend="{show:false}"
          ></VePie>
        </Card>
        <Card style="width:68%;text-align:center;font-size:16px;font-weight:bold;">
          {{'享受服务类别排行（前8）'}}
          <VeLine :data="enjoyServerData"></VeLine>
        </Card>
      </div>
      <Card style="width:98%;margin:50px 1% 0 1%;">
        <div
          style="
          display: flex;
          align-items: stretch;
          height: 100%;
          justify-content: space-around;
          margin: 20px 0;
          flex-wrap: wrap;
        "
        >
          <Card
            class="cardstyOther"
            style="background: #2d8cf0;margin:20px 0;"
            v-for="(items,indexs) in socialArr"
            :key="indexs"
          >
            <div>
              <h1>{{items.value}}</h1>
              <p>{{items.name}}</p>
            </div>
          </Card>
        </div>
      </Card>
      <!-- <Card style="width:98%;margin:50px 1% 0 1%;">
        <div style="text-align:center;font-size:16px;font-weight:bold;">{{'社工服务站各项目服务居民参与量分析'}}</div>
        <div class="container">
          <div class="container-wai">
            <div class="container-div" v-for="(items,indexs) in socialArr" :key="indexs">
              <span class="item-name">{{items.name}}</span>
              <span class="item-num">{{items.value}}</span>
              <span class="ring-style"></span>
            </div>
          </div>
        </div>
      </Card>-->
    </template>
  </LiefengContent>
</template>

<script>
//@route('/chartoperate');
import LiefengContent from "@/components/LiefengContent3";
import VeLine from "v-charts/lib/line.common";
import VePie from "v-charts/lib/pie.common";
import VeRing from "v-charts/lib/ring.common";
import VeHistogram from "v-charts/lib/histogram.common";

export default {
  data() {
    return {
      viewNum: 0, // 浏览总数
      enjoyNum: 0,
      // 折线图数据
      settingsparam: {
        //   radius: 80
      },
      // 环形图默认字体隐藏
      ringextend: {
        label: {
          normal: {
            show: false,
            position: "inside"
          }
        }
      },
      // 最受欢迎的日常服务点击量排行
      welcomeData: {
        columns: ["分类", "人数"],
        rows: []
      },
      // 柱状图的样式
      hisExtend: {
        series: {
          barMaxWidth: 50
        }
      },
      // 最受欢迎服务参与数分析
      bestWelecome: {
        columns: ["分类", "人数"],
        rows: []
      },
      // 享受服务数据
      enjoyServerData: {
        columns: ["分类", "人数"],
        rows: []
      },
      // 参与社会服务数据
      socialData: [{ columns: ["value", "name"], rows: [] }],
      socialArr: []
    };
  },
  methods: {
    // 方法部分
    // 转格式的公共接口
    mapData(data) {
      let newData = [];
      data.map(item => {
        newData.push({ 分类: item.name, 人数: item.num });
      });
      return newData;
    },
    // 接口部分
    // 获取总数的接口
    getNumber() {
      this.$get(
        "/statistic/api/symanage/pc/statColumn/statColumnPublishBrowseSum"
      ).then(res => {
        this.viewNum = res.browsePv;
        this.enjoyNum = res.participatePv;
      });
    },
    // 社工站各服务参与量的接口
    getSocialWork() {
      this.$get("/old/api/pc/sy/stat/orgParticipateService").then(res => {
        let data = res.data;
        data.map(item => {
          this.socialArr.push({ name: item.name, value: item.num });
        });
      });
    },
    //享受服务柱状图接口
    getEnjoyServer() {
      this.$get("/old/api/pc/sy/stat/participateServiceRank").then(res => {
        let data = res.data;
        this.enjoyServerData.rows = this.mapData(data);
      });
    },
    // 最受欢迎饼图接口
    getWelcome() {
      this.$get("/old/api/pc/sy/stat/participateService").then(res => {
        let data = res.data;
        this.bestWelecome.rows = this.mapData(data);
      });
    },
    // 获取享受服务点击接口
    getEnjoyClick() {
      this.$get("/old/api/pc/sy/stat/serviceBrowseRank").then(res => {
        let data = res.data;
        this.welcomeData.rows = this.mapData(data);
      });
    }
  },
  created() {
    // 获取总数

    this.getNumber();
    this.getSocialWork();
    this.getEnjoyServer();
    this.getWelcome();
    this.getEnjoyClick();
  },
  components: {
    LiefengContent,
    VeLine,
    VePie,
    VeRing,
    VeHistogram
  }
};
</script>
    
<style scoped lang='less'>
.time-span {
  margin-top: 5px;
  font-size: 16px;
  .tiem-title {
    margin-right: 2px;
  }
}
.cardsty {
  min-width: 250px;
  width: 22%;
  background: #2db7f5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardsty p {
  font-size: 16px;
  margin-top: 20px;
}
.data-view {
  position: relative;
}
.data-num {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 70px;
  font-weight: bold;
  line-height: 438px;
}
// .data-num:hover {
//   font-size: 80px;
//   left: 34%;
// }
.container {
  width: 100%;

  .container-wai {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 20px 0;
  }
}
.container-div {
  margin: 20px 0;
  position: relative;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  // background-color: lightgrey;
  .item-name {
    position: absolute;
    width: 300px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .item-num {
    width: 300px;
    text-align: center;
    position: absolute;
    top: 50%;
    font-size: 60px;
    z-index: 999;
    font-weight: bold;
  }
}
.ring-style {
  display: inline-block;
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #19d4ae;
  width: 260px;
  height: 260px;
  border-radius: 260px;
}
.ring-style::before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 200px;
  height: 200px;
  border-radius: 200px;
}

.cardstyOther {
  min-width: 250px;
  width: 22%;
  background: #2db7f5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
}
.cardstyOther p {
  font-size: 16px;
  margin-top: 20px;
}
</style>